import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import transform from 'lodash/transform'
import forEach from 'lodash/forEach'
import pickBy from 'lodash/pickBy'
import omitBy from 'lodash/omitBy'
import split from 'lodash/split'
import mapKeys from 'lodash/mapKeys'
import replace from 'lodash/replace'
import some from 'lodash/some'
import wcs from './wcs/index'
import i18n from '../i18n-setup'
import { loadSectionTranslations } from '../i18n-setup'
import { formatPrice, getPropertyByPath } from './utils/string.js'
import prices from '../locales/prices.json'
import algoliaDashFilters from '../helpers/dashFiltersAlgolia'
import { getCurrentLocale, getCurrencySymbol, getCurrentCountry } from './utils/currentCountry'
import { isValidUrl } from './utils/url'
import { pdp } from '../store/modules/pdp';

const { storeId } = wcs.getStoreConfig()

let currentPlpCategory = window.algoliaConfig?.algoliaCategoryIdentifier || ''
const isChanel = currentPlpCategory === 'brands_chanel'
const rangePrice = Boolean(window.algoliaConfig?.priceRange)
  ? JSON.parse(window.algoliaConfig?.priceRange)
  : prices
// function used to normalize the response from Algolia plp products list
export const getAlgoliaPlpProductsNormalized = function(res) {
  if (res) {
    const pagination = {}
    pagination.currentPage = res.page + 1 || undefined
    pagination.pageSize = res.hitsPerPage || undefined
    pagination.totalPages = res.nbPages || undefined
    pagination.totalProducts = res.nbHits || undefined
    const { hits } = res
    const productsNormalized = hits.map(product => getAlgoliaProductNormalized(product))
    return {
      products: productsNormalized,
      ...pagination,
    }
  }
  return {}
}

// function used to normalize the response from Algolia instant search
export const getAlgoliaSuggestionsNormalized = function(
  products,
  suggestions,
  contentResult,
  userData,
  redirect
) {
  let suggestedWords = []
  let spellcheck = []
  let collationQuery = ''
  let searchTermToReplace = ''
  let productsNormalized = {}
  let normalizedContentResults = {}
  let highlightSuggestedWords = []

  // if the redirect URL is present, the page will be redirected to the given URL on submit
  // added check to manage absolute and relative URLs
  let redirectUrl = redirect?.url || ''
  const countryChunk = `/${getCurrentCountry()}`
  if (!isEmpty(redirectUrl)) {
    redirectUrl =
      redirectUrl.includes(countryChunk) || isValidUrl(redirectUrl)
        ? redirectUrl
        : `${countryChunk}${redirectUrl}`
  }

  if (products || !isEmpty(suggestions?.hits)) {
    if (products) {
      productsNormalized = products //already normalized
    }
    if (suggestions) {
      const { hits } = suggestions
      suggestedWords.push(...hits.map(hit => hit.query))
      highlightSuggestedWords.push(...hits.map(hit => hit._highlightResult?.query?.value))
    }
  }

  if (contentResult) {
    normalizedContentResults = getAlgoliaContentNormalized(contentResult)
  }

  Object.assign(productsNormalized, {
    products: productsNormalized.products || [],
    suggestedWords,
    spellcheck,
    collationQuery,
    searchTermToReplace,
    redirectUrl,
    contentResults: normalizedContentResults,
    highlightSuggestedWords,
    userData: userData || [],
  })

  return productsNormalized
}

export const buildAlgoliaProductUrl = productUrl =>
  productUrl
    ? `${window.location.origin}/${getCurrentCountry()}${
        productUrl.startsWith('/') ? '' : '/'
      }${productUrl}`
    : '#'

// function used to normalize the single product from Algolia plp
export const getAlgoliaProductNormalized = inputItem => {
  let outputItem = {}
  loadSectionTranslations('plp')
  const saleBadgeActive = window.algoliaConfig?.saleBadgeActive == 'true'
  const isRoxableActive = window.algoliaConfig?.RxActive || false
  const isEngravingActive = window.algoliaConfig?.EngravingActive || false
  const isRemixActive = window.algoliaConfig?.RemixActive || false
  const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}
  const installmentFeatureActive = window.wcs_feature_paymentInstallment?.isActive || false
  const sortBadgePath = Boolean(window.algoliaConfig?.isEarlyAccessParam)
    ? 'sortBadge_EarlyAccess'
    : 'sortBadge_Guest'

  // for polarized badge
  outputItem.isPolarized =
    inputItem.attributes.POLARIZED && inputItem.attributes.POLARIZED != 'FALSE'

  const now = Date.now()
  const locale = getCurrentLocale() || 'en-US'
  const currency = inputItem.prices.LISTPRICE?.currency || 'USD'

  const discountedPrice = discountedPriceValue(inputItem.prices, priceNameConfig)

  const listPriceFallback = listPriceFallbackValue(inputItem.prices)

  const listPrice =
    getPropertyByPath(inputItem.prices, priceNameConfig.ListPrice) || listPriceFallback
  const offerPrice = getPropertyByPath(inputItem.prices, discountedPrice) || ''
  const isOfferPriceDateValid = offerDateValid(inputItem.prices, discountedPrice)

  if (offerPrice && offerPrice < listPrice && isOfferPriceDateValid) {
    //todo a cookie for identifying a user segment is mentioned in n1 docs, where it is on sgh?
    outputItem.price = getAlgoliaFormattedPrice(offerPrice, locale, currency) || undefined
    outputItem.priceRaw = offerPrice || undefined
    outputItem.onSale = {
      promo: inputItem.promo || undefined, //not used
      originalPrice: getAlgoliaFormattedPrice(listPrice, locale, currency) || undefined,
    }
  } else {
    outputItem.price = listPrice
      ? getAlgoliaFormattedPrice(listPrice, locale, currency)
      : offerPrice
      ? getAlgoliaFormattedPrice(offerPrice, locale, currency)
      : i18n.t('plp_pending_price')
    outputItem.priceRaw = listPrice || undefined
  }
  outputItem.currency = currency || undefined
  outputItem.brand = inputItem.attributes.BRAND || undefined
  outputItem.title = outputItem.brand || undefined
  outputItem.upc = inputItem.partnumberId || undefined //todo check mapping
  outputItem.mfpartnumber = inputItem.partnumberId || undefined // todo check mapping
  outputItem.localizedColorLabel = Array.isArray(inputItem.attributes_translated.FRONT_COLOR)
    ? inputItem.attributes_translated.FRONT_COLOR[0]
    : inputItem.attributes_translated.FRONT_COLOR || undefined
  outputItem.modelName = Array.isArray(inputItem.attributes_translated.MODEL_NAME)
    ? inputItem?.attributes_translated?.MODEL_NAME[0]
    : inputItem?.attributes_translated?.MODEL_NAME?.includes('|')
    ? inputItem?.attributes_translated?.MODEL_NAME?.split('|')[0]
    : inputItem?.attributes_translated?.MODEL_NAME || undefined

  outputItem.modelCode = Array.isArray(inputItem.attributes.MODEL_CODE_DISPLAY)
    ? inputItem.attributes.MODEL_CODE_DISPLAY[0]
    : inputItem.attributes.MODEL_CODE_DISPLAY || undefined

  outputItem.sku = Array.isArray(inputItem.attributes.SKU)
    ? inputItem.attributes.SKU[0]
    : inputItem.attributes.SKU || undefined

  outputItem.frameShape = Array.isArray(inputItem.attributes.FRAME_SHAPE)
    ? inputItem.attributes.FRAME_SHAPE[0]
    : inputItem.attributes.FRAME_SHAPE || undefined

  let isElectronics = inputItem.attributes?.PRODUCT_TYPE?.toLowerCase() === 'electronics'
  let isGenderKidsRayban = inputItem.attributes.GENDER === 'CHILD'

  outputItem.categoryItem = isElectronics
    ? 'Electronics'
    : isGenderKidsRayban
    ? 'RAYBANJUNIOR'
    : inputItem.attributes.BRAND.toUpperCase()

  outputItem.lensTechnology = Array.isArray(inputItem.attributes.LENS_TREATMENT)
    ? inputItem.attributes.LENS_TREATMENT.join(',')
    : inputItem.attributes.LENS_TREATMENT || undefined

  outputItem.frameTechnology = Array.isArray(inputItem.attributes_translated.FRAME_MATERIAL_FACET)
    ? inputItem.attributes_translated.FRAME_MATERIAL_FACET
    : inputItem.attributes_translated.FRAME_MATERIAL_FACET || undefined

  outputItem.badgeBelowModelName = inputItem.badgeBelowLeft || undefined //mapping?
  outputItem.category = getBadgeBelowRight(inputItem) || undefined

  outputItem.showIconCategory =
    (inputItem.badgeBelowRight && inputItem.badgeBelowRight.iconIdentifier) || false //mapping?

  outputItem.img = getPlpImages(inputItem)[0]?.url
    ? `${getPlpImages(inputItem)[0]?.url}?impolicy=SGH_bgtransparent&width=640`
    : undefined
  outputItem.imgHover = getPlpImages(inputItem)[1]?.url
    ? `${getPlpImages(inputItem)[1]?.url}?impolicy=SGH_bgtransparent&width=640`
    : undefined
  outputItem.imgPreview = outputItem.imgHover
  outputItem.pdpURL = buildAlgoliaProductUrl(inputItem.url)
  outputItem.URL = outputItem.pdpURL
  outputItem.customizeUrl = buildAlgoliaProductUrl(inputItem.remixableUrl) || undefined //mapping?

  outputItem.isEngravable =
    inputItem.attributes.ENGRAVABLE &&
    inputItem.attributes.ENGRAVABLE != 'FALSE' &&
    isEngravingActive
  outputItem.isCustomizable =
    inputItem.attributes.CUSTOMIZABLE &&
    inputItem.attributes.CUSTOMIZABLE != 'FALSE' &&
    isRemixActive
  outputItem.isStella =
    (inputItem.attributes.PRODUCT_TYPE && inputItem.attributes.PRODUCT_TYPE === 'Electronics') ||
    false
  outputItem.isJunior = Array.isArray(inputItem.attributes.GENDER)
    ? inputItem.attributes.GENDER.includes('CHILD')
    : inputItem.attributes.GENDER === 'CHILD'
  outputItem.juniorLabel = inputItem.juniorLabel || undefined //mapping?

  let isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
  outputItem.isLoyaltyItem =
    isLoyaltyActive &&
    inputItem.attributes.CUSTOMER_TYPE &&
    inputItem.attributes.CUSTOMER_TYPE === 'Loyalty'

  const isPresentWishlist = { isInWishlist: false }

  outputItem.wishlistRelatedLinks = isPresentWishlist

  outputItem.isFindInStore = inputItem.isFindInStore || undefined //mapping?
  outputItem.isEmailMeWhenAvailable = inputItem.isEmailMeWhenAvailable || undefined //mapping?
  outputItem.isOutOfStock = false //mapping?
  if (!isEmpty(inputItem.colors)) {
    outputItem.colorsNumber =
      inputItem.colors.length === 0 ? 0 : ` ${inputItem.colors.length} ${i18n.t('plp_colors')}`
    outputItem.colorCount = outputItem.colorsNumber
  }
  outputItem.catentryId = inputItem.productId || undefined

  outputItem.isRoxable = Boolean(inputItem.attributes.ROXABLE === 'TRUE') && isRoxableActive
  outputItem.roxableLabel = inputItem.roxableLabel || undefined //mapping?

  outputItem.Status = inputItem.status //mapping?
  outputItem.lifecycle = inputItem.attributes.LIFECYCLE
  outputItem.objectID = inputItem.objectID || undefined

  if (!isEmpty(inputItem.colors) && inputItem.colors.length > 1) {
    outputItem.colors = inputItem.colors.map(variantItem => {
      const variantDiscountedPrice = discountedPriceValue(variantItem.prices, priceNameConfig)
      let offerPriceValue =
        getPropertyByPath(
          variantItem.prices,
          discountedPriceValue(variantItem.prices, priceNameConfig)
        ) || undefined
      let priceValue =
        getPropertyByPath(variantItem.prices, priceNameConfig.ListPrice) ||
        listPriceFallbackValue(variantItem.prices)
      let offerPrice, price
      const isOfferPriceDateValid = offerDateValid(variantItem.prices, variantDiscountedPrice)

      if (offerPriceValue && offerPriceValue < priceValue && isOfferPriceDateValid) {
        price = getAlgoliaFormattedPrice(priceValue, locale, currency)
        offerPrice = getAlgoliaFormattedPrice(offerPriceValue, locale, currency)
      } else {
        price = priceValue
          ? getAlgoliaFormattedPrice(priceValue, locale, currency)
          : offerPriceValue
          ? getAlgoliaFormattedPrice(offerPriceValue, locale, currency)
          : i18n.t('plp_pending_price')
      }

      return {
        img: getPlpImages(variantItem)[0]?.url
          ? `${getPlpImages(variantItem)[0]?.url}?impolicy=SGH_bgtransparent&width=640`
          : undefined,
        imgHover: getPlpImages(variantItem)[1]?.url
          ? `${getPlpImages(variantItem)[1]?.url}?impolicy=SGH_bgtransparent&width=640`
          : undefined,
        price: price,
        offerPrice: offerPrice,
        pdpURL: buildAlgoliaProductUrl(variantItem.url),
        catentryId: variantItem.productId,
        upc: variantItem.partnumberId || undefined,
        isPolarized:
          variantItem.attributes.POLARIZED && variantItem.attributes.POLARIZED != 'FALSE',
        isLoyaltyItem:
          isLoyaltyActive &&
          variantItem.attributes.CUSTOMER_TYPE &&
          variantItem.attributes.CUSTOMER_TYPE === 'Loyalty',
        saleBadge:
          saleBadgeActive && variantItem[sortBadgePath] && isOfferPriceDateValid
            ? getSaleBadge(variantItem[sortBadgePath])
            : undefined,
        category: getBadgeBelowRight(variantItem) || undefined,
        objectID: variantItem.objectID || undefined,

        //todo map following fields:
        // showIconCategory:
        //   (variantItem.badgeBelowRight && variantItem.badgeBelowRight.iconIdentifier) || undefined,
      }
    })
  }
  if (installmentFeatureActive) {
    const installmentsInfo = getInstallmentMessage(locale, currency, outputItem.priceRaw)
    outputItem.installmentsInfo = installmentsInfo
  }

  outputItem.saleBadge =
    saleBadgeActive && inputItem[sortBadgePath] && isOfferPriceDateValid
      ? getSaleBadge(inputItem[sortBadgePath])
      : undefined

  return outputItem
}

function getPlpImages(product) {
  const plpImages = product.attachments?.filter(attachment => attachment.rule === 'PLP')
  const sortedPlpImages = plpImages?.sort((a, b) => a.sequence - b.sequence)
  return sortedPlpImages ?? []
}

export const getSaleBadge = valueBadge => {
  const { saleBadgeColor, saleBadgeImgName, saleBadgeImgPath } = window.algoliaConfig || {}
  const saleBadge = {
    badgeColor: saleBadgeColor && JSON.parse(saleBadgeColor)[valueBadge],
    badgeImage:
      saleBadgeImgName && saleBadgeImgPath && JSON.parse(saleBadgeImgName)[valueBadge]
        ? saleBadgeImgPath + JSON.parse(saleBadgeImgName)[valueBadge]
        : undefined,
    badgeValue: valueBadge || undefined,
  }

  return saleBadge
}

// function used to normalize the minicart inventoryavailability api response from Algolia
export const getAlgoliaMinicartSuggestionNormalized = response => {
  loadSectionTranslations('plp')
  const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}
  const locale = getCurrentLocale() || 'en-US'

  let products = []
  for (let product of response) {
    if (product.inventoryQuantity > 0) {
      products.push(product)
    }
  }

  if (products) {
    let items = []
    let countProducts = 0

    countProducts = products.length
    countProducts > 3 ? (countProducts = 3) : countProducts
    const productsToShow = products.slice(0, countProducts)
    productsToShow.forEach(element => {
      const discountedPrice = discountedPriceValue(element.prices, priceNameConfig)
      const listPriceFallback = listPriceFallbackValue(element.prices)

      let item = {}
      let currency = element.prices.LISTPRICE?.currency || 'USD'
      let isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'

      const listPrice = getPropertyByPath(element.prices, priceNameConfig.ListPrice)
        ? getPropertyByPath(element.prices, priceNameConfig.ListPrice)
        : listPriceFallbackValue(element.prices)
        ? listPriceFallbackValue(element.prices)
        : offerPrice
        ? offerPrice
        : i18n.t('plp_pending_price')

      let offerPrice = getPropertyByPath(element.prices, discountedPrice) || ''

      item.productName = Array.isArray(element.attributes.MODEL_NAME)
        ? element.attributes.MODEL_NAME[0]
        : element.attributes.MODEL_NAME || undefined
      item.standardPrice = offerPrice ? offerPrice : listPrice
      item.fullPrice = listPrice || ''
      item.productId = element.productId
      item.img = getPlpImages(element)[0]?.url || ''
      item.brandName = element.attributes.BRAND || ''
      item.description = element.longDescription || ''
      item.qty = element.inventoryQuantity || ''
      item.productPartNumber = element.partnumberId
      item.isLoyaltyItem = element.isLoyaltyItem
      item.url = buildAlgoliaProductUrl(element.url)
      let tealiumProduct = {
        Sku: item.productName,
        Category: item.brandName.toUpperCase(),
        Price: item.standardPrice,
        PriceFull: item.standardPrice,
        ModelName: item.productName,
        UPC: item.productPartNumber,
        uniqueid: item.productId,
        Engraving: 'N',
        Status: 'Available',
        Type: 'STD',
        Units: 1,
        ModelCode: '',
        Lens: '',
        Frame: '',
        Case: '',
        LoyaltyExclusive: isLoyaltyActive ? (item.isLoyaltyItem ? 'Y' : 'N') : '',
      }
      item.tealiumProducts = tealiumProduct
      items.push(item)
    })

    return {
      items: items || '',
      count: countProducts || '',
    }
  }
  return {}
}

function calculateCountSum(range, priceGuest, priceStats) {
  if (!priceGuest) {
    return 0
  }
  let pricesArray = Object.entries(priceGuest)
  //add min and max to pricesArray because priceRange is incomplete due to maxvaluefacet api limited to 100
  pricesArray.push([priceStats.min, 1])
  pricesArray.push([priceStats.max, 1])
  let elementsInRange = pricesArray
    .filter(([key, value]) => {
      let keyPars = parseFloat(key)
      return keyPars >= range[0] && keyPars <= range[1]
    })
    .map(([key, value]) => ({ key, value }))
  let sum = elementsInRange.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    0
  )
  return sum
}
export const getAlgoliaPlpFacetsNormalized = async (payload, orderBy = 'default') => {
  const { facets, facets_stats, nbHits } = payload
  const res = {}
  if (nbHits == 0) return { numResult: 0 }
  const { locale } = wcs.getStoreConfig()
  let pilotDashFilterName = ''
  const isEngravingActive = window.algoliaConfig?.EngravingActive || false
  const isRemixActive = window.algoliaConfig?.RemixActive || false
  const isRoxableActive = window.algoliaConfig?.RxActive || false

  const sortPrice = Boolean(window.algoliaConfig?.isEarlyAccessParam)
    ? 'sortPrice_EarlyAccess'
    : 'sortPrice_Guest'

  //build facetPrice id (used for facet match and also for url update):
  let facetPrice = {}
  rangePrice[locale]?.forEach(e => {
    if (e.length > 1) {
      let smalPrice = String(e[0]).length >= 4 ? Number(e[0]) : e[0]
      let igPrice = String(e[1]).length >= 4 ? Number(e[1]) : e[1]
      facetPrice[`${smalPrice}TO${igPrice}`] = calculateCountSum(
        [e[0], e[1]],
        facets[sortPrice],
        facets_stats[sortPrice]
      )
    } else {
      let priceSingle = String(e[0]).length >= 4 ? Number(e[0]) : e[0]
      facetPrice[`>${priceSingle}`] = calculateCountSum(
        [e[0], Infinity],
        facets[sortPrice],
        facets_stats[sortPrice]
      )
    }
  })

  facets.price = facetPrice

  // Created new array to add new keys in case of key "attributes_translated.CHANNEL_DETAILS' and 'attributes_translated.FRAME_SHAPE_FACET'.
  // "attributes_translated.CHANNEL_DETAILS' and 'attributes_translated.FRAME_SHAPE_FACET' have more then one filter value.
  // New keys will have name formed by "key_subKey" ("attributes_translated.CHANNEL_DETAILS_ONLINE ONLY").
  // To match keys present in dashFiltersAlgolia json
  // In the case of "attributes_translated.CHANNEL_DETAILS' we create as many objects as there are subKey
  // In the case of "attributes_translated.FRAME_SHAPE_FACET' we create as new object with key 'attributes_translated.FRAME_SHAPE_FACET_Pilot'
  const updatedFacets = transform(
    facets,
    (result, value, key) => {
      if (key === 'attributes_translated.CHANNEL_DETAILS') {
        forEach(value, (subValue, subKey) => {
          subKey === 'EXCLUSIVE SGH' || subKey === 'ONLINE ONLY'
            ? (result[`${key}?${subKey}`] = { [subKey]: subValue })
            : null
        })
      } else if (key === 'attributes_translated.FRAME_SHAPE_FACET' && !isChanel) {
        forEach(value, (subValue, subKey) => {
          const subKeyEn = subKey.includes('|') ? split(subKey, '|')[1] : subKey
          if (subKeyEn === 'Pilot') {
            pilotDashFilterName = subKey
            result[`${key}?${subKeyEn}`] = { [subKey]: subValue }
          } else {
            result[key] = value
          }
        })
      } else if (key === 'attributes_translated.CUSTOMIZABLE' && !isRemixActive) {
        delete result[key]
      } else if (key === 'attributes_translated.ENGRAVABLE' && !isEngravingActive) {
        delete result[key]
      } else if (key === 'attributes_translated.ROXABLE' && !isRoxableActive) {
        delete result[key]
      }
      // create a new combined filter attribute_translated.SALE based on value of sortDiscount fields data and early access flag
      else if (key === 'sortDiscount_Guest' || key === 'sortDiscount_EarlyAccess') {
        if (window.algoliaConfig?.isEarlyAccessParam) {
          let zeroValue = 0
          let sum = 0
          for (const [key, value] of Object.entries(facets['sortDiscount_EarlyAccess'])) {
            if (key === '0.0') {
              zeroValue = value
            } else if (parseFloat(key) > 0) {
              sum += value
            }
          }
          result['attributes_translated.SALE'] = {
            FALSE: zeroValue,
            TRUE: sum,
          }
        } else if (key === 'sortDiscount_Guest') {
          let zeroValue = 0
          let sum = 0
          for (const [key, value] of Object.entries(facets['sortDiscount_Guest'])) {
            if (key === '0.0') {
              zeroValue = value
            } else if (parseFloat(key) > 0) {
              sum += value
            }
          }
          result['attributes_translated.SALE'] = {
            FALSE: zeroValue,
            TRUE: sum,
          }
        }
      } else {
        result[key] = value
      }
    },
    {}
  )

  //new array with only filters of type Dash
  const facetsDash = pickBy(updatedFacets, (value, key) => algoliaDashFilters()[key])

  //new array with only non-Dash type filters
  const facetsWithoutDash = omitBy(updatedFacets, (value, key) => algoliaDashFilters()[key])

  const iconTranslation = id => {
    return id.includes('|') ? split(id, '|')[1] : id
  }
  const descTranslation = id => {
    let descTranslation = ''
    //add exception for photocromatic which is hardcoded from attributes to facets options
    if (id === i18n.t('attributes_translated.PHOTOCHROMIC')) descTranslation = 'Photocromatic'
    else descTranslation = id.includes('|') ? split(id, '|')[1] : id
    return descTranslation
  }
  const capitalizeWords = str => str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())

  const labelTranslation = (id, key) => {
    //build label for price filters
    var labelPriceOption = ''
    if (key == 'price') {
      let price_over = 'Over'
      if (!getCurrentLocale().startsWith('en')) {
        price_over = i18n.t('plp_price_over')
      }
      labelPriceOption =
        `${getCurrencySymbol()} ` + id.replace('TO', ' - ').replace('>', ` ${price_over} `)
      labelPriceOption = replace(labelPriceOption, /(\d+(\.\d+)?)/g, match =>
        parseFloat(match).toLocaleString(getCurrentLocale())
      )
    }
    let idOriginal = ''
    if (!getCurrentLocale().startsWith('en')) {
      if (id.includes('|')) {
        const idParts = split(id, '|')
        id = idParts[0]
        idOriginal = idParts[1]
      }
    }

    let labelTranslated = ''
    //handle exception for some fr-CA labels:
    const keyLabel = `${key}_Label_${idOriginal.replaceAll(/ /g, '_')}`
    if ((getCurrentLocale() == 'fr-CA' || getCurrentLocale() == 'es-ES') && i18n.te(keyLabel)) {
      labelTranslated = i18n.t(keyLabel)
    } else {
      labelTranslated = id
    }

    return key === 'attributes_translated.GENDER'
      ? capitalizeWords(labelTranslated)
      : key === 'price'
      ? labelPriceOption
      : labelTranslated
  }

  const hasIcon = key => {
    return (
      [
        'attributes_translated.FRONT_COLOR_FACET',
        'attributes_translated.FRAME_SHAPE_FACET',
        'attributes_translated.LENS_COLOR_FACET',
        'attributes_translated.LENS_COLOR_FACET',
      ].includes(key) && !isChanel
    )
  }

  //delete filters or options used but not needed in UI
  if (facetsWithoutDash['attributes_translated.GENDER']) {
    const unisexEntry = Object.entries(
      facetsWithoutDash['attributes_translated.GENDER']
    )?.find(([key, value]) => key.toUpperCase().includes('UNISEX'))
    unisexEntry ? delete facetsWithoutDash['attributes_translated.GENDER'][unisexEntry[0]] : null
  }

  delete facetsWithoutDash['attributes_translated.GENDER']?.Men
  delete facetsWithoutDash['attributes_translated.GENDER']?.Women
  delete facetsWithoutDash['attributes_translated.GENDER']?.Kids

  //delete data used but not needed in UI
  delete facetsWithoutDash['sortDiscount_Guest']
  delete facetsWithoutDash['sortDiscount_EarlyAccess']
  delete facetsWithoutDash['attributes_translated.ON_SALE']
  delete facetsWithoutDash['attributes_translated.ON_EA_SALE']

  res.facetsWithoutDashList = map(facetsWithoutDash, (value, key) => {
    const keyTranslated = key === 'price' ? 'price' : i18n.t(key)
    const label = key === 'price' ? i18n.t('plp_price') : keyTranslated
    return {
      groupName: key,
      label: label,
      list: map(value, (count, id) => {
        let desc = null
        const descKey = `${key}_${descTranslation(id.replaceAll(/ /g, '_'))}`
        if (i18n.te(descKey)) desc = i18n.t(descKey)
        return {
          id: keyTranslated + ':' + id,
          count,
          icon: hasIcon(key) ? iconTranslation(id.toLowerCase()).replace(/ /g, '-') : undefined,
          iconSize: hasIcon(key) ? 'small' : undefined,
          facetIdentifier: keyTranslated + ':' + id,
          label: labelTranslation(id, key),
          desc: desc,
        }
      }),
    }
  })

  const groupsToDelete = ['sortPrice_EarlyAccess', 'sortPrice_Guest']
  // Change position of Price to sortPrice in filter
  groupsToDelete.forEach(groupName => {
    let indexToDelete = res.facetsWithoutDashList.findIndex(item => item.groupName === groupName)
    const indexToExchange = res.facetsWithoutDashList.findIndex(item => item.groupName === 'price')
    // Swap the objects at the specified indexes
    if (indexToDelete !== -1 && indexToExchange !== -1)
      [res.facetsWithoutDashList[indexToDelete], res.facetsWithoutDashList[indexToExchange]] = [
        res.facetsWithoutDashList[indexToExchange],
        res.facetsWithoutDashList[indexToDelete],
      ]

    indexToDelete = res.facetsWithoutDashList.findIndex(item => item.groupName === groupName)
    if (indexToDelete !== -1) {
      res.facetsWithoutDashList.splice(indexToDelete, 1)
    }
  })

  if (Object.keys(facetsDash).length > 0) {
    res.facetsDashList = [
      {
        groupName: 'dashCheckBoxes',
        label: 'dashCheckBoxes',
        list: map(facetsDash, (value, key) => {
          const facetsDashKey =
            key === 'attributes_translated.FRAME_SHAPE_FACET?Pilot'
              ? mapKeys(facetsDash[key], (value, key) => key.split('|').pop())
              : facetsDash[key]
          const facetsDashId =
            key === 'attributes_translated.FRAME_SHAPE_FACET?Pilot'
              ? pilotDashFilterName
              : algoliaDashFilters()[key].count
          const keyValue =
            key === 'attributes_translated.FRAME_SHAPE_FACET?Pilot'
              ? 'attributes_translated.FRAME_SHAPE_FACET'
              : key

          return {
            id: i18n.t(`${keyValue}`) + ':' + facetsDashId,
            count:
              facetsDashKey[algoliaDashFilters()[key].count] !== undefined
                ? facetsDashKey[algoliaDashFilters()[key].count]
                : 0,
            icon: algoliaDashFilters()[key].icon,
            iconSize: 'small',
            facetIdentifier: i18n.t(`${keyValue}`) + ':' + facetsDashId,
            label: i18n.t(`${key}`),
          }
        }),
      },
    ]
    //sorted manually pilot and exclusive dash filter (not with dashboard)
    res.facetsDashList = sortFacetDash(res.facetsDashList)

    res.filterOptionsList = [...res.facetsDashList, ...res.facetsWithoutDashList]
  } else {
    res.filterOptionsList = res.facetsWithoutDashList
  }

  res.numResult = nbHits

  //todo understand how Algolia sends us sort options
  let sortOptionsList = [
    {
      id: '1',
      label: i18n.t('plp_newest'),
    },
    {
      id: 'default',
      label: i18n.t('plp_suggested'),
    },
    {
      id: '3',
      label: i18n.t('plp_low_high'),
    },
    {
      id: '4',
      label: i18n.t('plp_high_low'),
    },
  ]
  res.sortOptionsList = sortOptionsList.map((item = {}) => ({
    ...item,
    selected: item.id === orderBy,
  }))

  return res
}

export const setLocale = locale => {
  return locale == 'es-ES' ? 'de-DE' : locale
}

const offerDateValid = (price, path) => {
  const pathNoOfferPrice = path.replace(/\.offerPrice$/, '')
  const startDate = price[pathNoOfferPrice]?.startDate
  const endDate = price[pathNoOfferPrice]?.endDate
  return startDate && endDate ? isDateValid(startDate, endDate) : false
}

const dateToUTC = date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const isDateValid = (start, end) => {
  const currentDateUTC = dateToUTC(new Date())
  const arrStart = start.split(/[- :]/)
  const arrEnd = end.split(/[- :]/)
  const startDate = new Date(
    arrStart[0],
    arrStart[1] - 1,
    arrStart[2],
    arrStart[3],
    arrStart[4],
    arrStart[5]
  )
  const endDate = new Date(arrEnd[0], arrEnd[1] - 1, arrEnd[2], arrEnd[3], arrEnd[4], arrEnd[5])

  return currentDateUTC >= startDate && currentDateUTC <= endDate
}
export const getInstallmentMessage = (locale, currency, price) => {
  const minTotalAmount = window.wcs_feature_paymentInstallment.minTotalAmount,
    maxInstallmentAmount = window.wcs_feature_paymentInstallment.maxInstallmentAmount

  let values = ''
  if (minTotalAmount != undefined && maxInstallmentAmount != undefined) {
    let minTotalAmountFmt = parseFloat(minTotalAmount),
      maxInstallmentAmountFmt = parseFloat(maxInstallmentAmount),
      installmentCount = 1
    for (let i = 1; i <= maxInstallmentAmountFmt; i++) {
      let currentPrice = price / i
      if (currentPrice >= minTotalAmountFmt) {
        installmentCount = i
      }
    }
    let priceToRender = getAlgoliaFormattedPrice(price / installmentCount, locale, currency)
    values = i18n.t('plp_installments_info', [installmentCount, priceToRender])
  }
  return values
}

export const getAlgoliaFormattedPrice = (price, locale, curr, priceWithoutSymbol = false) => {
  const customLocale = setLocale(locale)

  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1
  let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1
  // Discard Chrome since it also matches Safari
  if (chromeAgent && safariAgent) safariAgent = false
  const currencyDisplayValue = safariAgent ? 'symbol' : 'narrowSymbol'

  let numberFormat = new Intl.NumberFormat(customLocale, {
    style: 'currency',
    currency: curr,
    currencyDisplay: currencyDisplayValue,
  })
  let formattedPrice = numberFormat.format(parseFloat(formatPrice(price), 10))
  const symbol = numberFormat.formatToParts().find(part => part.type === 'currency').value

  if (customLocale == 'fr-CA' && formattedPrice.length > 7) {
    formattedPrice = formattedPrice.replace(',', '.').replace(/\s/g, ',')
    formattedPrice = formattedPrice.replace(`,${symbol}`, '')
  }

  const countryListArr = ['de-DE', 'fr-CA', 'fr-FR', 'es-ES']
  const priceNum = `${formattedPrice.replace(symbol, '').trim()}`

  if(priceWithoutSymbol) {
    return priceNum
  }

  return countryListArr.includes(customLocale) ? `${priceNum}${symbol}` : `${symbol}${priceNum}`
}

export const getBadgeBelowRight = item => {
  loadSectionTranslations('plp')
  const { categories, attributes_translated } = item
  const {
    CHANNEL_DETAILS,
    RUNWAY,
    LAUNCH_DATE_CODE,
    LIMITED_EDITION,
    AVANT_PREMIERE_DATE,
    SPECIAL_PROJECT_TYPE,
    CUSTOMER_TYPE,
  } = attributes_translated

  const hasCategoryBestSeller = some(categories, category =>
    category.toLowerCase().startsWith('best_seller')
  )
    ? true
    : false

  const { inventoryQuantity, lifecycle } = item
  const now = Date.now()

  if (inventoryQuantity == 0)
    return lifecycle ? i18n.t('plp_badge_coming_back') : i18n.t('plp_badge_sold_out')
  if (CHANNEL_DETAILS === 'CRM EXCLUSIVE') return i18n.t('plp_badge_members_only')
  if (CHANNEL_DETAILS === 'ONLINE ONLY')
    return i18n.t('attributes_translated.CHANNEL_DETAILS?ONLINE ONLY')
  if (RUNWAY === 'TRUE') return i18n.t('plp_badge_runway')
  if (['SGH EXCLUSIVE', 'EXCLUSIVE SGH'].includes(CHANNEL_DETAILS))
    return i18n.t('attributes_translated.CHANNEL_DETAILS?EXCLUSIVE SGH')
  if (['ONLY AT', 'AT ONLY'].includes(CHANNEL_DETAILS))
    return i18n.t('attributes_translated.CHANNEL_DETAILS?ONLY AT')
  if (AVANT_PREMIERE_DATE && Date.parse(AVANT_PREMIERE_DATE) > now)
    return i18n.t('plp_badge_find_it_here_first')
  if (
    SPECIAL_PROJECT_TYPE &&
    window.algoliaConfig?.collectionType?.includes(SPECIAL_PROJECT_TYPE.toLowerCase())
  )
    return i18n.t('plp_badge_collaboration')

  if (hasCategoryBestSeller) {
    return i18n.t('attributes_translated.MOST_WANTED_STYLES')
  }

  if (CUSTOMER_TYPE === 'Loyalty')
    return i18n.t('attributes_translated.CUSTOMER_TYPE')
  if (LIMITED_EDITION === 'TRUE') return i18n.t('attributes_translated.LIMITED_EDITION')
  if (LAUNCH_DATE_CODE && window.algoliaConfig?.newBadgeDateList?.includes(LAUNCH_DATE_CODE))
    return i18n.t('plp_badge_new')

  return null
}

export const discountedPriceValue = (price, { EarlyAccess, OfferPrice }) => {
  const earlyAccessPrice = getPropertyByPath(price, EarlyAccess)
  const offerPrice = getPropertyByPath(price, OfferPrice)
  const earlyAccessDateValid = offerDateValid(price, EarlyAccess)
  const offerPriceDateValid = offerDateValid(price, OfferPrice)

  if (
    window.algoliaConfig?.isEarlyAccessParam &&
    earlyAccessPrice &&
    earlyAccessDateValid &&
    (!offerPrice || !offerPriceDateValid || parseFloat(earlyAccessPrice) < parseFloat(offerPrice))
  )
    return EarlyAccess

  return OfferPrice
}

export const listPriceFallbackValue = prices => {
  const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}
  return getPropertyByPath(prices, priceNameConfig.OfferPrice) || ''
}

export const sortFacetDash = facetsDashList => {
  //if the sale filter is present,
  //exclusive is moved to the penultimate position while pilot is moved to the third last position,
  //otherwise the two filters are moved respectively to the last and penultimate position
  const { list } = facetsDashList[0]
  const dashFiltersSorted = []

  const dashFiltersToMove = ['pilot', 'exclusive', 'sale']

  dashFiltersToMove.map(dashFilter => {
    const index = list.findIndex(item => item.icon === dashFilter)
    if (index > 0) {
      dashFiltersSorted.push(list.splice(index, 1)[0])
    }
  })

  list.push(...dashFiltersSorted)

  return facetsDashList
}

export const getTrendingFacets = async facetName => {
  return [
    {
      href: '#',
      label: 'Polarized Collection',
    },
    {
      href: '#',
      label: 'Best Sellers',
    },
    {
      href: '#',
      label: 'The Sun Club',
    },
    {
      href: '#',
      label: 'Sun-Kissed Savings: Dive into exclusive deals with our summer specials',
    },
    {
      href: '#',
      label: 'Unleash Your Potential: Optimal vision with our performance sports shades',
    },
  ]
}

export const getAlgoliaContentNormalized = responseResult => {
  const countryChunk = `/${getCurrentCountry()}`
  const content = []
  const { hits } = responseResult
  const paginationInfo = {}

  paginationInfo.totalItems = responseResult.nbHits || undefined
  paginationInfo.currentPage = responseResult.page + 1 || undefined
  paginationInfo.totalPages = responseResult.nbPages || undefined
  paginationInfo.pageSize = responseResult.hitsPerPage || undefined

  content.push(
    ...hits.map(hit => {
      const titleText = hit._highlightResult?.title?.value || hit.title || ''
      return {
        headingTitle: titleText,
        linkUrl: `${countryChunk}${hit?.url}`,
        id: hit?.objectID,
      }
    })
  )

  return {
    paginationInfo,
    content,
  }
}

export const getAlgoliaTopBrandsNormalized = response => {
  const brands = []
  response.hits.forEach(element => {
    const brandImage = getBrandsDetails(element.facetValue).image
    if (brandImage !== undefined && brands.length < 6) {
      brands.push({
        alt: `${element.facetValue}`,
        id: 'SearchPanel_Results_link',
        imageSrc: brandImage,
        link: `/${getCurrentCountry()}/${getBrandsDetails(element.facetValue).link}`,
      })
    }
  })

  return brands
}

const getBrandsDetails = brandName => {
  const brandPath = '//assets.sunglasshut.com/extra/image/LuxotticaRetail/SGH_brands_logos/'
  const chanelPath = '/wcsstore/SGH/experiences/UK/chanel/'
  switch (brandName) {
    case 'Alain Mikli':
      return {
        image: `${brandPath}AM%20Logo_bk_new.png`,
        link: 'alain-mikli',
      }
    case 'Armani Exchange':
      return {
        image: `${brandPath}armani-exchange_bl_18.png`,
        link: 'armani-exchange',
      }
    case 'Arnette':
      return {
        image: `${brandPath}arnette_bl_18.png`,
        link: 'arnette',
      }
    case 'Burberry':
      return {
        image: `${brandPath}burberry_bl_18.png`,
        link: 'burberry',
      }
    case 'Bvlgari':
      return {
        image: `${brandPath}bulgari_bl_18.png`,
        link: 'bvlgari',
      }
    case 'Celine':
      return {
        image: `${brandPath}celine_bl_18.png`,
        link: 'celine',
      }
    case 'Chanel':
      return {
        image: `${chanelPath}CHANELlogo.jpg`,
        link: 'chanel',
      }
    case 'Chloé':
      return {
        image: `${brandPath}chloe%5Fbl%5F18.png`,
        link: 'chloe',
      }
    case 'Coach':
      return {
        image: `${brandPath}coach_bl_18.png`,
        link: 'coach',
      }
    case 'Costa':
      return {
        image: `${brandPath}costa_bl_18.png`,
        link: 'costa',
      }
    case 'DIOR':
      return {
        image: `${brandPath}dior_bl_18.png`,
        link: 'dior',
      }
    case 'Dolce&Gabbana':
      return {
        image: `${brandPath}dolcegabbana_bl_18.png`,
        link: 'dolce-and-gabbana',
      }
    case 'Gucci':
      return {
        image: `${brandPath}gucci_bl_18.png`,
        link: 'gucci',
      }
    case 'Loewe':
      return {
        image: `${brandPath}loewe_bl_18.png`,
        link: 'loewe',
      }
    case 'Maui Jim':
      return {
        image: `${brandPath}maui-jim_bl_18.png`,
        link: 'maui-jim',
      }
    case 'Michael Kors':
      return {
        image: `${brandPath}michael_kors_18.png`,
        link: 'michael-kors',
      }
    case 'Miu Miu':
      return {
        image: `${brandPath}miu-miu_bl_18.png`,
        link: 'miu-miu',
      }
    case 'Oakley':
      return {
        image: `${brandPath}oakley_bl_18.png`,
        link: 'oakley',
      }
    case 'Oliver Peoples':
      return {
        image: `${brandPath}oliver_peoples_18.png`,
        link: 'oliver-peoples',
      }
    case 'Persol':
      return {
        image: `${brandPath}persol_bl_18.png`,
        link: 'persol',
      }
    case 'Polo Ralph Lauren':
      return {
        image: `${brandPath}polo-ralph-lauren_bl_18.png`,
        link: 'polo-ralph-lauren',
      }
    case 'Prada':
      return {
        image: `${brandPath}prada_bl_18.png`,
        link: 'prada',
      }
    case 'Prada Linea Rossa':
      return {
        image: `${brandPath}prada-linea-rossa_red_18.png`,
        link: 'prada-linea-rossa',
      }
    case 'Ralph':
      return {
        image: `${brandPath}ralph_bl_18.png`,
        link: 'ralph',
      }
    case 'Ralph Lauren':
      return {
        image: `${brandPath}ralph-lauren_bl_18.png`,
        link: 'ralph-lauren',
      }
    case 'Ray-Ban':
      return {
        image: `${brandPath}ray-ban_wt_18.png`,
        link: 'ray-ban',
      }
    case 'Saint Laurent':
      return {
        image: `${brandPath}YSL_bl.png`,
        link: 'saint-laurent',
      }
    case 'Sunglass Hut Collection':
      return {
        image: `${brandPath}sghcollection_bl_18.png`,
        link: 'sunglass-hut-collection',
      }
    case 'Swarovski':
      return {
        image: `${brandPath}swarovski_bl.png`,
        link: 'swarovski',
      }
    case 'Tiffany & Co.':
      return {
        image: `${brandPath}tiffany_bl_18.png`,
        link: 'tiffany-co',
      }
    case 'Tom Ford':
      return {
        image: `${brandPath}tomford_18.png`,
        link: 'tom-ford',
      }
    case 'Versace':
      return {
        image: `${brandPath}LOGO-VERSACE.png`,
        link: 'versace',
      }
    case 'Vogue Eyewear':
      return {
        image: `${brandPath}VO_LOGO_2020xSGH.png`,
        link: 'vogue-eyewear',
      }
    default:
      return ''
  }
}

export const normalizeProductPrice = pricesObject => {
  const productPrice = {
    currency: '',
    discountBadge: '',
    discountedPrice: 0,
    formattedDiscountedPrice: '',
    formattedFullPrice: '',
    fullPrice: 0,
    upc: '',
    priceWithoutSymbol: '',
    discountedPriceWithoutSymbol: '',
    loyaltyPoints: null,
  }

  const locale = getCurrentLocale() || 'en-US'
  const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}
  const discountObjectName = discountedPriceValue(pricesObject, priceNameConfig)
  const discountedPrice = getPropertyByPath(pricesObject, discountObjectName)
  let fullPricePath = priceNameConfig.ListPrice
  let fullPrice = getPropertyByPath(pricesObject, fullPricePath)
  if (!fullPrice) {
    fullPrice = listPriceFallbackValue(pricesObject)
    fullPricePath = priceNameConfig.OfferPrice
  }
  const currencyPath = fullPricePath.split('.')[0] + '.currency'
  const currency = getPropertyByPath(pricesObject, currencyPath) || 'USD'
  const loyaltyPath = fullPricePath.split('.')[0] + '.loyaltyPoints'
  let loyaltyPoints = getPropertyByPath(pricesObject, loyaltyPath) || null

  productPrice.fullPrice = fullPrice
  productPrice.discountedPrice = discountedPrice
  productPrice.formattedFullPrice = fullPrice
    ? getAlgoliaFormattedPrice(fullPrice, locale, currency)
    : undefined
  productPrice.formattedDiscountedPrice = discountedPrice
    ? getAlgoliaFormattedPrice(discountedPrice, locale, currency)
    : undefined

  productPrice.currency = currency
  productPrice.priceWithoutSymbol = fullPrice
  ? getAlgoliaFormattedPrice(fullPrice, locale, currency, true)
  : undefined
  productPrice.discountedPriceWithoutSymbol = discountedPrice
  ? getAlgoliaFormattedPrice(discountedPrice, locale, currency, true)
  : undefined

  //ged badge
  if (discountedPrice && fullPrice > discountedPrice) {
    productPrice.discountBadge = getPropertyByPath(
      pricesObject,
      discountObjectName.replace(/[\.#].*$/, '.badge')
    )
    loyaltyPoints = getPropertyByPath(pricesObject, discountObjectName.replace(/[\.#].*$/, '.loyaltyPoints'))
  }
  productPrice.loyaltyPoints = Number(loyaltyPoints)

  return productPrice
}

export const normalizeProductDataForAnalytics = (currentProduct = {}, giftCardQuantity = 1) => {
  return {
    productIndex: currentProduct.upc,
    productData: {
      Status: 'Available',
      Category: currentProduct.skuType,
      LensType: currentProduct.availableForRX ? 'RX' : 'PLANO',
      Price: normalizeProductPrice(currentProduct.prices).priceWithoutSymbol,
      PriceFull: normalizeProductPrice(currentProduct.prices).priceWithoutSymbol,
      Sku: currentProduct.analytics.prodobj.Sku,
      ModelName: currentProduct.analytics.prodobj.ModelName,
      FrameTechnology: currentProduct.analytics.prodobj.FrameTechnology,
      Brand: currentProduct.analytics.prodobj.Brand,
      Shape: currentProduct.analytics.prodobj.Shape,
      Size: currentProduct.analytics.prodobj.Size,
      LensTechnology: currentProduct.analytics.prodobj.LensTechnology,
      FrameColor: currentProduct.analytics.prodobj.FrameColor,
      LoyaltyExclusive: currentProduct.analytics.prodobj.LoyaltyExclusive,
      ModelCode: currentProduct.modelCode,
      Url: currentProduct.analytics.prodobj.Url,
      Units: `${giftCardQuantity}` || '1', 
      FrameType: '',
      Engraving: '',
      Lens: '',
      Frame: '',
      Case: '',
    }
  }
}


export const normalizeProductObjDataForAnalytics = (currentProduct = {}, upc) => {
  const productPriceObj = normalizeProductPrice(currentProduct.prices)
  const { discountedPrice, fullPrice } = productPriceObj
  const isDiscounted = discountedPrice < fullPrice

  return {
    productIndex: upc,
    productData: {
      Sku: currentProduct.analytics?.prodobj.Sku,
      Category: currentProduct.analytics?.prodobj.Category, 
      Vto: currentProduct.analytics?.prodobj.Vto,
      Engraving: 'N', 
      Status: getProductStatus(true, currentProduct.active, Number(currentProduct.InventoryAvailability?.[0]?.availableQuantity), isExlusive(currentProduct), isLimitEdition(currentProduct)),
      Units: currentProduct.analytics?.prodobj.Units || '', 
      ModelName: currentProduct.analytics?.prodobj.ModelName, 
      Price: isDiscounted ? productPriceObj.discountedPriceWithoutSymbol : productPriceObj.priceWithoutSymbol,
      PriceFull: productPriceObj.priceWithoutSymbol,
      LensColor: currentProduct.analytics?.prodobj.LensColor, 
      LensTechnology: currentProduct.analytics?.prodobj.LensTechnology, 
      FrameColor: currentProduct.analytics?.prodobj.FrameColor, 
      FrameTechnology: currentProduct.analytics?.prodobj.FrameTechnology, 
      Shape: currentProduct.analytics?.prodobj.Shape, 
      Brand: currentProduct.analytics?.prodobj.Brand, 
      Size: currentProduct.analytics?.prodobj.Size, 
      pricewithoutengraving: productPriceObj.priceWithoutSymbol,
      pricewithengraving: (Number(productPriceObj.priceWithoutSymbol) + Number(pdp.state.engravingInfo?.engravingData?.unformattedFullPrice || 0)).toString(),
      OosOptions: getOosOptions(true, currentProduct.active, Number(currentProduct.InventoryAvailability?.[0]?.availableQuantity), isExlusive(currentProduct), isLimitEdition(currentProduct)),
      Image: currentProduct.analytics?.prodobj.Image, 
      Url: currentProduct.analytics?.prodobj.Url, 
      LensType: 'PLANO',
      FrameType: '', 
      LoyaltyExclusive: currentProduct.analytics?.prodobj.LoyaltyExclusive,
      Conf_IsUpcSupported: currentProduct.analytics?.prodobj.Conf_IsUpcSupported,
    }
  }
}

function isLimitEdition(product) {
  return product.badges?.topSection.some(
    badge => badge.identifier === 'LIMITED_EDITION'
  )
}

function isExlusive(product) {
  return product.badges?.topSection.some(
    badge => badge.identifier === 'PDP_EXCLUSIVE'
  )
}

function getProductStatus(display, lifecycle, stock, isExclusive, limitedEdition) {
  if (!display) {
    return "/";
  }

  if (!lifecycle && stock === 0 && !isExclusive && !limitedEdition) {
    return "Not-for-sale";
  }

  if (display) {
    if (lifecycle && stock === 0 && (isExclusive)) {
      return "Out-of-stock"
    }
    if (lifecycle && stock === 0 ) {
      return "SoldOut";
    }
    if (lifecycle && stock > 2) {
      return "Available-fewLeft";
    }
    if (lifecycle && stock === 2) {
      return "Available-twoLeft";
    }
    if (lifecycle && stock === 1) {
      return "Available-oneLeft";
    }
    if (stock > 0) {
      return "Available";
    }
  }

  // Add any additional conditions based on the rest of the table.
  return "Available";
}

function getOosOptions(display, lifecycle, stock, isExclusive, limitedEdition) {
  // Check conditions for "Undisplay"
  if (!display) {
    return "/";
  }

  // Check conditions for "EmailMeWhenAvailable","CustomizeIt","FindStore","SearchSimilar"
  if (display) {
    if ((stock === 0 && lifecycle && isExclusive) ||
      (stock === 0 && limitedEdition) ||
      (stock > 0 && isExclusive)) {
      return `"EmailMeWhenAvailable","CustomizeIt","FindStore","SearchSimilar"`;
    }

    // Check conditions for "SearchSimilar"
    if ((stock === 0 && !lifecycle && !isExclusive && !limitedEdition) ||
    (stock === 0 && limitedEdition)) {
      return `"SearchSimilar"`;
    }
  }

  // Return unknown or a default message if no conditions are met
  return "EmailMeWhenAvailable", "CustomizeIt", "FindStore", "SearchSimilar";
}