import axios from 'axios'
import { getPlpProductsNormalized } from '../normalizer'
import { getFrameGeniusProductNormalized } from '../normalizer'
import wcs from './index'
import { getCurrentCountry } from '../utils/currentCountry'
import { FRAME_GENIUS, FRAME_GENIUS_USER_PROFILE_DATA } from '../utils/white-label'

export const getFaPlpProducts = async function(UPCS) {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/plp/${storeId}/byPartNumbers/` + UPCS.join()
  let data = {}
  try {
    data = await axios.get(url)
    return getPlpProductsNormalized(data)
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const getFrameGeniusPlpProducts = async function(UPCS) {
  if (UPCS) {
    const domain = wcs.getEnvConfig('currentDomain') || ''
    const { storeId } = wcs.getStoreConfig()
    const url = `${domain}/wcs/resources/plp/${storeId}/byPartNumbers/` + UPCS.join()
    let response = {}
    try {
      response = await axios.get(url)
      const products = response.data.plpView.products.products.product
      const wcsProducts = products.map(product => getFrameGeniusProductNormalized(product))
      return wcsProducts
    } catch (error) {
      return error.response
    }
  }
  return console.error('getFrameGeniusPlpProducts: UPCS is empty')
}

export const getFaUserProfileData = async function() {
  const isUserLogged = wcs.isUserLogged()
  if (isUserLogged) {
    const domain = wcs.getEnvConfig('currentDomain') || ''
    const { storeId } = wcs.getStoreConfig()
    const url = `${domain}/wcs/resources/store/${storeId}/person/sgh/@self/fauserdata?_${new Date().getTime()}`
    let res = {}
    let data = {}
    try {
      res = await axios.get(url)
      data = res.data
      if (Object.keys(data).length > 0) {
        return data
      }
    } catch (error) {
      console.error(error)
    }
  } else {
    // TODO: handle case for not logged user
  }
}

export const saveFaUserProfileData = async function() {
  const isUserLogged = wcs.isUserLogged()
  const userProfileStored = sessionStorage.getItem(FRAME_GENIUS_USER_PROFILE_DATA)
  const userProfilePayload = JSON.parse(userProfileStored)

  if (isUserLogged) {
    const domain = wcs.getEnvConfig('currentDomain') || ''
    const { storeId } = wcs.getStoreConfig()
    const url = `${domain}/wcs/resources/store/${storeId}/person/sgh/@self/fauserdata`
    let res = {}
    try {
      res = await axios.post(url, userProfilePayload)
      sessionStorage.removeItem(FRAME_GENIUS_USER_PROFILE_DATA)
      return res.data
    } catch (error) {
      console.error(error)
    }
  } else {
    localStorage.setItem('isFloatingButtonActive', true)
    redirectFromFaToLogin()
  }
}

const redirectFromFaToLogin = () => {
  const redirectURL = `/${getCurrentCountry()}/myaccount/login?origin=${FRAME_GENIUS}`
  window.location = `${window.location.origin}${redirectURL}`
}

export const getPdpProductSizes = () => {
  const productSizes = window.productSizesArray
  if (productSizes && Object.keys(productSizes).length) {
    let obj = {}
    for (let i in productSizes) {
      obj = { ...obj, ...productSizes[i] }
    }
    return obj
  }
}

export const matchSize = (sizeSuggested, fgLabels) => {
  const { locale } = wcs.getStoreConfig()
  const isBadgeVisible = sessionStorage.getItem('isSuggestedSizeBadgeAvailable') == 'true'
  const sizeList = window.productDataSize
  if (isBadgeVisible && window.productDataSize.length > 0) {
    for (const property in sizeSuggested) {
      let result = sizeList.find(element => element.currentSizeItem === property)
      result.fgLabel = sizeSuggested[property]
    }
  }
}

export const isFloatingButtonActive = sessionStorage.getItem('isFloatingButtonActive') == 'true'
