// for Landscape
const TABLET_WIDTH = 768
const TABLETLANDSCAPE_WIDTH = 1024
const DESKTOP_WIDTH = 1440
const LARGE_WIDTH = 1980

// breakpoint for swiper
export const breakpoints = {
  tablet: TABLET_WIDTH + 1,
  tabletLandscape: TABLETLANDSCAPE_WIDTH,
  desktop: DESKTOP_WIDTH + 1,
}

// screeens for tailwind
export const tailWindScreens = {
  tablet: '768px',
  tabletLandscape: '1024px',
  desktop: '1440px',
  large: '1980px',
}

// STRINGS

// PAGES
export const PDP_CONTAINER = '#pdp'
export const CHECKOUT_CONTAINER = '.checkout-page-wrapper'

// VIRTUAL MIRROR
export const VIRTUAL_MIRROR_KEY = '090c2638-044d-4915-bcca-c4b8d0228184'

// SA FILTERS
let SA_HINGE_ID

export const setSAHingeFilterId = hingeId => {
  SA_HINGE_ID = hingeId
}

export const getSAHingeFilterId = () => {
  return SA_HINGE_ID && SA_HINGE_ID
}

// GOOGLE API KEY
export const GOOGLE_MAP_API_KEY = 'AIzaSyBwy3hyEZ4p3URLbo-GSQh9wsaZTCUMX2s'
// GOOGLE MAP URL
export const GOOGLE_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap?'

export const cmsColor = {
  'mine-shaft': '#222222',
  'emperor-grey': '#555555',
  'mercury-white': '#e6e6e6',
  'alabaster-white': '#f7f7f7',
  'blaze-orange': '#ff6c00',
  'pumpkin-orange': '#ff7714',
  'apricot-orange': '#ef8670',
  'tulip-tree-yellow': '#f2b140',
  'double-spanish-white': '#e8dbbd',
  'hippie-blue': '#539bb9',
  'razzmatazz-pink': '#d60b51',
  'buttercup-yellow': '#f6ae10',
  'paris-daisy-yellow': '#fff46e',
  'apricot-pink': '#ef8670',
  'ligth-gray': '#fcfcfc',
  'white-smoke': '#f5f5f5',
  white: '#ffffff',
}

// VIDEO EXTENSIONS
export const VIDEO_EXTENSIONS = ['.avi', '.mpe', '.mpeg', '.mp4', '.mpg', '.mov', '.mpv']

export const devicePixelRatio = window.devicePixelRatio
