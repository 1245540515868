import axios from 'axios'
import qs from 'qs'
import { getPlpProductsNormalized, getPlpFacetsNormalized } from '../normalizer'
import {
  getAlgoliaPlpProductsNormalized,
  getAlgoliaPlpFacetsNormalized,
} from '../algolia-normalizer'
import wcs from './index'
import VueCookies from 'vue-cookies'
import { getSAHingeFilterId } from '../utils/const'
import isEmpty from 'lodash/isEmpty'
import union from 'lodash/union'
import find from 'lodash/find'
import {
  getAlgoliaPlpFacets,
  algoliaPlpGroupedSearch,
  algoliaPlpUngroupedSearch,
  mergeProdsAndVariants,
  formatFacetsLogic,
  algoliaAnalyticsEvents,
} from '../utils/algoliaIntegration'
import { getCurrencySymbol } from '../utils/currentCountry'
import { getWishlistProducts } from '../../libs/wcs/wishlist'
import { decodeFiltersI18n } from '../utils/algoliaIntegration'
import i18n from './../../i18n-setup'

const useAlgolia = window.algoliaConfig?.isActive || false
let currentPlpCategory = window.algoliaConfig?.algoliaCategoryIdentifier || ''
const sortPrice = window.algoliaConfig?.isEarlyAccessParam
  ? 'sortPrice_EarlyAccess'
  : 'sortPrice_Guest'

const sortDiscount = window.algoliaConfig?.isEarlyAccessParam
  ? 'sortDiscount_EarlyAccess'
  : 'sortDiscount_Guest'

const hingeFilterIdSA = getSAHingeFilterId()

const clearUlr = (url, regex) => {
  // const tmpUrl = url.replace(/(\?|&)+(currentPage|orderBy){1}=([0-9]|default){1}/g, '').replace()
  const urlArray = url.split('&').reduce((acc, item) => {
    if (!!!item.match(regex)) {
      acc.push(item)
    }
    return acc
  }, [])
  return urlArray.join('&')
}

const getConfig = payload => {
  if (!payload) {
    return {}
  }

  let { idsFiltersCheckedList, extraFacets, ...rest } = payload
  if (!isEmpty(extraFacets)) {
    idsFiltersCheckedList = idsFiltersCheckedList
      ? union([...extraFacets, ...idsFiltersCheckedList])
      : extraFacets
  }

  let config = Object.assign({}, rest)
  const earlyAccessParam = VueCookies.get('earlyAccess')

  if (idsFiltersCheckedList && idsFiltersCheckedList.length > 0) {
    let paramsTMP = {
      params: { facet: idsFiltersCheckedList, ...rest },
      paramsSerializer: function(params) {
        const { facet, ...rest } = params
        let paramStr = qs.stringify({ facet }, { arrayFormat: 'repeat' })
        paramStr += '&' + qs.stringify(rest)
        return paramStr
      },
    }
    if (earlyAccessParam) paramsTMP.params.earlyAccess = earlyAccessParam
    return paramsTMP
  }

  if (earlyAccessParam) config.earlyAccess = earlyAccessParam
  return { params: config }
}

// lodash function to search an object in a nested array
const findValue = (arr, value) => {
  let result = {}
  find(arr, elem => {
    result = find(elem.list, { id: value })
    if (result)
      result = {
        facetIdentifier: result.facetIdentifier,
        groupName: elem.groupName,
        label: result.label,
      }
    return result
  })
  return result
}

//change price filter format to be compoatible with api call to be performed:
function replacePriceCall(filter) {
  return filter.includes('>')
    ? filter.replace('price:', `${sortPrice} `)
    : filter.replace('price:', `${sortPrice}: `)
}

// Retrieve applied filters and groupNames and returns a partial query string
const getConfigCMSBanners = payload => {
  let facetIdentifiers = ''
  let groupNames = ''
  let finalQueryString = ''

  let { filters, checkedFilters } = payload
  if (!isEmpty(checkedFilters)) {
    for (let indexCheckedFilter in checkedFilters) {
      const foundFilter = findValue(filters, checkedFilters[indexCheckedFilter])
      const fixedGroupName = useAlgolia
        ? foundFilter.groupName.replace('attributes_translated.', '')
        : foundFilter.groupName
      const facetIdentifier =
        foundFilter.groupName === 'price' ? foundFilter.label : foundFilter.facetIdentifier
      if (useAlgolia && facetIdentifier.includes(':'))
        facetIdentifier = facetIdentifier.split(':')[1]
      if (useAlgolia && facetIdentifier.includes('|'))
        facetIdentifier = facetIdentifier.split('|')[1]
      indexCheckedFilter == 0
        ? (facetIdentifiers = '&facetIdentifier=' + encodeURIComponent(facetIdentifier))
        : (facetIdentifiers += ',' + encodeURIComponent(facetIdentifier))
      indexCheckedFilter == 0
        ? (groupNames = '&facetName=' + encodeURIComponent(fixedGroupName))
        : (groupNames += ',' + encodeURIComponent(fixedGroupName))
    }
  }

  finalQueryString = facetIdentifiers + groupNames

  return finalQueryString
}

// function used to obtain the parameter strings that are necessary for vue structure, the expected is an array with the same value that are returned from BE
const getParamQueryStringNormalized = queryString => {
  let params = {}
  const queryStringParsed = qs.parse(queryString, { delimiter: /[?&]/ })
  if (Object.keys(queryStringParsed).length > 0) {
    params = Object.keys(queryStringParsed).reduce((acc, element) => {
      // "amp;currentPage": 1
      const newKey = element.replace('amp;', '')
      // "currentPage": 1
      if (['facet', 'currentPage', 'orderBy'].includes(newKey)) {
        acc[newKey] = queryStringParsed[element]
      }
      return acc
    }, {})
  }
  // refactored to solve issue 9032 and 9592: now params are already properly encoded so we just push to arrayFacetNormalized
  if (params.facet) {
    let arrayFacetNormalized = []
    if ('string' === typeof params.facet) {
      arrayFacetNormalized.push(params.facet)
    } else {
      arrayFacetNormalized = params.facet
    }
    delete params.facet
    params['facet'] = arrayFacetNormalized
  }
  return params
}

//called at first plp page load
export const getPlpFacetStart = async payload => {
  const { extraFacets, searchTerm } = payload
  payload.currentPlpCategory = currentPlpCategory
  var facetNormalized = []
  var facetParamsNormalized = []
  facetParamsNormalized = qs.parse(decodeURIComponent(window.location.search), {
    ignoreQueryPrefix: true,
  })
  if (useAlgolia) {
    payload.idsFiltersCheckedList = facetParamsNormalized.facet
      ? [].concat(facetParamsNormalized.facet)
      : []

    const numericFiltersSelected = buildNumericFilters(payload.idsFiltersCheckedList)

    payload.ruleContexts = facetParamsNormalized.ruleContexts
    payload.numericFiltersSelected = numericFiltersSelected
    payload.searchTerm = facetParamsNormalized.searchTerm || searchTerm

    facetNormalized = await getAlgoliaPlpFacetsNormalized(
      await getAlgoliaPlpFacets(payload),
      facetParamsNormalized.orderBy
    )
  } else {
    facetNormalized = getPlpFacetsNormalized(
      await axios.get(wcs.getEndPoint('getPlpFacets'), getConfig(payload))
    )
    facetParamsNormalized = getParamQueryStringNormalized(
      // `${wcs.getEndPoint('getPlpFacets')}?facet=ads_f70020_ntk_cs%3A"Full+Rim"&facet=ads_f70009_ntk_cs%3A"Rectangle"&currentPage=1`
      wcs.getEndPoint('getPlpFacets')
    )
  }

  // if exist a sortOptionsList and is defined the value selected override the default with the response from BE
  const orderBy =
    facetNormalized.sortOptionsList && facetNormalized.sortOptionsList.find(ele => ele.selected)
  if (orderBy) {
    facetParamsNormalized.orderBy = orderBy.id
  }
  let facetToSelect = facetParamsNormalized.facet
    ? [].concat(facetParamsNormalized.facet).map(ele => {
        /*
for every facet (that I know the id) I should find the groupname corresponding
Before read this code, take a look of the file mock-plp-facet.js, considering facets json, and the expected object.
The structure that I should check is the same that I shoudl traverse to find, for each Id, the corresponding groupName
*/
        let elementFacetNormalizedWithGroupName = facetNormalized.filterOptionsList.find(item => {
          let e = null
          if (item.list) {
            e = item.list.find(l => {
              return ele === l.id
            })
          }
          return e
        })
        // if I found element add it to the groupName
        if (elementFacetNormalizedWithGroupName) {
          return {
            groupName: elementFacetNormalizedWithGroupName.groupName,
            id: ele,
          }
        } else if (ele.includes(hingeFilterIdSA)) {
          return {
            groupName: 'fit',
            id: decodeURIComponent(
              find(extraFacets, e => {
                return e.includes(hingeFilterIdSA)
              })
            ),
          }
        }
      })
    : []

  // facetParamsNormalized.facet is necessary only to construct the facetToSelect, after that remove the property .facet
  delete facetParamsNormalized.facet //todo lasciare?
  // SGHDP-9181: replace the [undefined] with empty array []
  facetToSelect = facetToSelect.filter(y => y !== undefined)
  return {
    facet: facetNormalized,
    facetParams: facetParamsNormalized,
    facetToSelect: facetToSelect,
  }
}

const mapFavoriteProducts = async productsPlp => {
  const wishlistProducts = await getWishlistProducts()
  wishlistProducts.products?.map(e => {
    productsPlp.hits.map(p => {
      if (p.productId === e.catentryId) {
        p.wishlistRelatedLinks = e.wishlistRelatedLinks
        p.wishlistRelatedLinks['isInWishlist'] = true
      }
    })
  })
  return productsPlp
}

//function to to get translated value for key attributes_translated.SALE in current language
export const getSaleFilterTranslated = () => {
  const saleTranslated = i18n.t('attributes_translated.SALE')
  return saleTranslated
}

// build nested numericFiltersSelected array, nesting translates to AND / OR in Algolia
export const buildNumericFilters = idsFiltersCheckedList => {
  let saleFilterTranslated = getSaleFilterTranslated()
  return idsFiltersCheckedList.reduce(
    (acc, filter) => {
      if (filter.toLowerCase().startsWith(saleFilterTranslated.toLowerCase())) {
        acc[0].push(`${sortDiscount}>0`)
      } else if (filter.toLowerCase().startsWith('price')) {
        acc[1].push(replacePriceCall(filter))
      }
      return acc
    },
    [[], []]
  )
}

//called at product list first load
export const getPlpProductsStart = async function(payload) {
  //payload: facets selected
  var { search, origin, pathname } = new URL(wcs.getEndPoint('getPlpProducts'))
  var currentParams = qs.parse(decodeURIComponent(search), {
    ignoreQueryPrefix: true,
  })
  var currentParamsUrl = qs.parse(decodeURIComponent(window.location.search), {
    ignoreQueryPrefix: true,
  })
  if (useAlgolia) {
    const { idsFiltersCheckedList } = payload
    const facetFilters = await formatFacetsLogic(idsFiltersCheckedList)
    const categoryFacet = [`categories:${currentPlpCategory}`]
    const numericFiltersSelected = buildNumericFilters(idsFiltersCheckedList)

    if (categoryFacet) {
      facetFilters.push(categoryFacet)
    }
    if (facetFilters) {
      var filteredArray = facetFilters.filter(element => {
        return (
          !element[0]?.toLowerCase().startsWith('price') &&
          !element[0]?.toLowerCase().startsWith('sortdiscount')
        )
      })
    }

    const options = {
      page: currentParams.currentPage,
      hitsPerPage: currentParams.pageSize,
      facetFilters: filteredArray,
      numericFilters: numericFiltersSelected,
      orderBy: payload.orderBy,
      query: currentParams.searchTerm || '',
      ruleContexts: currentParamsUrl.ruleContexts || '',
    }
    const addParams = {
      startPage: 0,
    }
    //retrieve main products with grouped index call:
    const products = await algoliaPlpGroupedSearch({ options, addParams })

    if (!isEmpty(products.hits)) {
      plpAnalytics(products) //todo move after LCP?
      return { data: getAlgoliaPlpProductsNormalized(products), mainProducts: products, options }
    }
  } else {
    // #SGHDP-9070 on first load if current page > 1
    // change pageSize in endpoint to retrieve all previous pages products
    // and change currentPage to 1 otherwise endpoint won't work probably because pageSize > totalProducts
    // then reset them UPDATE_RESET_PAGINATION_DATA
    let endpoint = wcs.getEndPoint('getPlpProducts')
    const useMockData = wcs.getEnvConfig('useMockData') || false

    if (!useMockData) {
      if (parseInt(currentParams.currentPage) > 1) {
        currentParams.pageSize =
          parseInt(currentParams.currentPage) * parseInt(currentParams.pageSize)
        currentParams.currentPage = 1
        const final = qs.stringify(currentParams, { arrayFormat: 'repeat' })
        endpoint = `${origin}${pathname}?${final}`
      }
    }
    return { data: getPlpProductsNormalized(await axios.get(endpoint)) }
  }
}

//get color variants, used only with algolia to delay ungrouped call
export const getProductsVariants = async payload => {
  const { mainProducts, options } = payload
  const variants = await algoliaPlpUngroupedSearch({ products: mainProducts, options })
  //merge main products and variants:
  let productsFinal = mergeProdsAndVariants({ products: mainProducts, variants })
  return getAlgoliaPlpProductsNormalized(productsFinal)
}

//called at each facet update
export const getPlpFacets = async payload => {
  if (useAlgolia) {
    const { orderBy, idsFiltersCheckedList, searchTerm } = payload

    const numericFiltersSelected = buildNumericFilters(idsFiltersCheckedList)

    payload.currentPlpCategory = currentPlpCategory
    payload.ruleContexts = payload.currentPlpCategory
    payload.numericFiltersSelected = numericFiltersSelected
    payload.searchTerm = searchTerm
    return getAlgoliaPlpFacetsNormalized(await getAlgoliaPlpFacets(payload), orderBy)
  } else {
    // from the endpoint present on source page "wcs_endpoint.getPlpFacets" remove the &facet or &orderBy parameters with key and value
    const url = clearUlr(wcs.getEndPoint('getPlpFacets'), /(facet|orderBy)/g)
    // once that the url is clear, facet and orderBy parameters are added from payload that is the section of the store containing facets Selected and order By
    return getPlpFacetsNormalized(await axios.get(url, getConfig(payload)))
  }
}

//called at product list update
export const getPlpProducts = async function(payload) {
  if (useAlgolia) {
    const { idsFiltersCheckedList } = payload
    const numericFiltersSelected = buildNumericFilters(idsFiltersCheckedList)
    const facetFilters = await formatFacetsLogic(idsFiltersCheckedList) //mergeFacetsForAlgoliaSearch(idsFiltersCheckedList) //formato desiderato: ["attributes.FRONT_COLOR_FACET:Yellow","attributes.FRONT_COLOR_FACET:Red"]
    const categoryFacet = [`categories:${currentPlpCategory}`]
    if (categoryFacet) {
      facetFilters.push(categoryFacet)
    }
    if (facetFilters) {
      var filteredArray = facetFilters.filter(element => {
        return (
          !element[0]?.toLowerCase().startsWith('price') &&
          !element[0]?.toLowerCase().startsWith('sortdiscount')
        )
      })
    }
    const options = {
      page: payload.currentPage,
      facetFilters: filteredArray,
      numericFilters: numericFiltersSelected,
      page: payload.currentPage,
      hitsPerPage: 18,
      orderBy: payload.orderBy,
      query: payload.searchTerm || '',
      ruleContexts: payload.ruleContexts || '',
    }

    const products = await algoliaPlpGroupedSearch({ options })

    //retrieve variants with ungrouped index call
    if (!isEmpty(products.hits)) {
      const variants = await algoliaPlpUngroupedSearch({ products, options })
      //merge main products and variants:
      const productsFinal = mergeProdsAndVariants({ products, variants })
      const productsFinalMerged = await mapFavoriteProducts(productsFinal)
      return getAlgoliaPlpProductsNormalized(productsFinalMerged)
    }
    return getAlgoliaPlpProductsNormalized(res) // TODO brakes when there are no products
  } else {
    const url = clearUlr(wcs.getEndPoint('getPlpProducts'), /(facet|currentPage|orderBy)/g)
    const data = getPlpProductsNormalized(await axios.get(url, getConfig(payload)))
    return data
  }
}
export const prepareFilterTrackingString = async function(
  idsFiltersCheckedList,
  filterOptionsList,
  facets,
  id,
  isInSearch = false
) {
  const PIPE = '|'

  let filterLists = idsFiltersCheckedList.map(facet => {
    const [facetFilterName, facetFilterValue] = facet.split(':')
    let filterName = capitalizeFirstLetter(facetFilterName)
    let filterValue = ''

    filterOptionsList.some(filter => {
      if (filter.list.some(el => el.id === facet)) {
        let filterGroupName = filter.groupName
        filterValue =
          filterGroupName === 'dashCheckBoxes' ? 'True' : capitalizeFirstLetter(facetFilterValue)
        return true
      }
    })

    return `${filterName}=${filterValue}`
  })

  let sortOptionSelected =
    id !== ''
      ? facets?.sortOptionsList.find(element => element.id == id)
      : facets?.sortOptionsList.find(element => element.selected)

  sortOptionSelected = capitalizeFirstLetter(sortOptionSelected.label).replace(/-/g, '')
  filterLists.push(`orderBy=${sortOptionSelected}`)
  let Search_FacetValues_String = filterLists.join(PIPE)

  let trackingData = {
    Search_ResultItemsQnt: facets.numResult.toString(),
    Search_FacetValues_String: Search_FacetValues_String,
  }
  if (isInSearch) {
    trackingData.Event_Source = 'SERB'
    trackingData.Search_View = 'SERB'
  }

  return trackingData
}

const capitalizeFirstLetter = str => {
  return str
    .split(' ')
    .map(el => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase())
    .join('')
}

const optimizedFilterList = [
  'attributes_translated.FRAME_SHAPE_FACET',
  'attributes_translated.FRAME_MATERIAL_FACET',
  'attributes_translated.FRAME_FITTING',
  'FRAME_TYPE',
  'attributes_translated.LENS_COLOR_FACET',
  'FACE_SHAPE',
  'attributes_translated.FRAME_MATERIAL_FACET',
  'attributes_translated.BRAND',
  'price',
  'attributes_translated.ROXABLE',
  'attributes_translated.GENDER',
  'attributes_translated.FRONT_COLOR_FACET',
  'attributes_translated.LENS_TREATMENT_FACET',
]

// https://luxotticaretail.atlassian.net/browse/SGHDP-9995
export const getPlpFilteredCMSBanners = async function(payload) {
  const isFilterSelected = decodeFiltersI18n(payload.checkedFilters).some(filter => {
    const filterID = filter.split(':')[0]
    return optimizedFilterList.includes(filterID)
  })
  if (isFilterSelected) {
    const { locale } = wcs.getStoreConfig()
    let data = {}
    const isPlpDynamicContentActive =
      sessionStorage.getItem('isPlpFacetDynamicContentActive') == 'true'
    if (isPlpDynamicContentActive) {
      const dynamicEndpoint = window.location.origin + '/webapp/wcs/stores/servlet/PLPTileBanners?'
      const originalUrl = wcs.getEndPoint('getPlpProducts')
      const url = clearUlr(
        originalUrl.split('?')[1],
        /(facet|currentPage|orderBy|isProductNeeded|isChanelCategory|pageSize|responseFormat|currency|viewTaskName|langId|pageView|beginIndex|top)/g
      )
      const finalUrl = dynamicEndpoint + url + '&locale=' + locale + getConfigCMSBanners(payload)
      try {
        data = await axios.get(finalUrl)
      } catch (error) {
        console.error('CMS Banners call failed')
      }
    } else {
      data.data = ''
    }
    return data.data
  }
}

export const plpAnalytics = products => {
  const analyticsParams = {
    eventAlgolia: 'viewedObjectIDs',
    queryId: products.queryID,
    objectIDs: products.objectIDs,
    index: products.indexName,
    eventName: products.isSearch ? 'Search - Landing page' : 'PLP - Landing page',
  }

  algoliaAnalyticsEvents(analyticsParams)
}

export const fetchFaqsFromWordLift = async url => {
  const apiUrl = `https://api.wordlift.io/data/${url.replace('https://', 'https/')}`

  try {
    const response = await axios.get(apiUrl)
    if (response.status === 200 && response.data) {
      return response.data
    }
  } catch (error) {
    console.log('Error in API call:', error)
    throw error
  }
}
